import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from './api.const';

export const dictionariesApi = createApi({
    reducerPath: 'dictionariesApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
    endpoints: (builder) => ({
        getIndicators: builder.query({
            query: () => 'get_indicators.php',
        }),
        getOrganizations: builder.query({
            query: () => 'get_organizations.php',
        }),
        getCountries: builder.query({
            query: () => 'get_countries.php',
        }),
        getIndicatorsByCountry: builder.query({
            query: (countryId) => `get_indicators_by_country.php?country=${countryId}`,
        }),
    }),
})

export const {
    useGetIndicatorsQuery,
    useGetOrganizationsQuery,
    useGetCountriesQuery,
    useGetIndicatorsByCountryQuery
} = dictionariesApi;