import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    region: null,
    year: null,
}

export const dataSlice = createSlice({
    name: 'data',
    reducerPath: 'dataSlice',
    initialState,
    reducers: {
        setRegion: (state, action) => {
            state.region = action.payload
        },
        setYear: (state, action) => {
            state.year = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setRegion, setYear } = dataSlice.actions