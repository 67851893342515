import { Button, CircularProgress, Grid, IconButton, TextField } from "@mui/material";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useLazyGetDemoAccessQuery } from "../../store/api/general.api";


export const DemoAccess = () => {
    const navigate = useNavigate();
    const [code, setCode] = useState(null);
    const [checkAccess] = useLazyGetDemoAccessQuery();

    const checkAccessHandler = useCallback(() => {
        if (!code) return;
        void checkAccess(code)
            .unwrap()
            .then((res) => {
                const accessIsAllowed = res?.accessIsAllowed;
                if (accessIsAllowed) {
                    const d = new Date();
                    localStorage.setItem('activationTime', d.getTime());
                    localStorage.setItem('accessCode', code);
                    navigate('/indicator/12276/country/6071');
                }
            });
    }, [code, checkAccess, navigate]);

    const clearStorage = useCallback(() => {
        localStorage.removeItem('activationTime');
        localStorage.removeItem('accessCode');
    }, [])


    return (
        <Grid container height='100vh' width='100vw' justifyContent='center' alignItems='center' flexDirection='column' gap='30px'>
            <img src="https://statbase.ru/upload/CPriority/8ad/eirbhhxit174ieq0i8z26k8e1212041o/statbase%203.png" height={'30px'} />
            <Grid container flexDirection='column' gap='10px' width='300px'>
                <TextField
                    size="small"
                    name="accessCode"
                    label='Код демо доступа'
                    onChange={(event) => {
                        const value = event.target.value;
                        setCode(value)
                    }}
                    value={code}
                />
                <Button variant="outlined" onClick={checkAccessHandler}>Ввести</Button>
            </Grid>
            {/* <Button variant="outlined" onClick={clearStorage}>Очистить</Button> */}
        </Grid>
    );
}
