
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from './api.const';

const createUrlParams = (data) => {
    const { indicatorId, selectedYear, selectedRegion, fullDataset } = data;
    const organizationParams = selectedRegion?.id ? `&organization=${selectedRegion.id}` : '';
    const yearParams = selectedYear?.id ? `&year=${selectedYear.id}` : '&year=2023';
    const fullDatasetParams = fullDataset ? `&fullDataset=1` : '';
    return `get_data_by_year.php?indicator=${indicatorId}${yearParams}${organizationParams}${fullDatasetParams}`;
}
const createDataByIndicatorUrlParams = (data) => {
    const { indicatorId, selectedYear, selectedRegion, fullDataset } = data;
    const organizationParams = selectedRegion?.id ? `&organization=${selectedRegion.id}` : '';
    /**
     * selectedYear?.id !== 0 
     * значение id = 0 зарезервировано
     * для вывода "последний доступный год"
     */
    const yearParams = selectedYear?.id && selectedYear?.id !== 0 ? `&year=${selectedYear.id}` : '';
    const fullDatasetParams = fullDataset ? `&fullDataset=1` : '';
    // return `test.php?indicator=${indicatorId}${yearParams}${organizationParams}${fullDatasetParams}`;
    return `get_data_by_indicator.php?indicator=${indicatorId}${yearParams}${organizationParams}${fullDatasetParams}`;
}

export const vizualizationApi = createApi({
    reducerPath: 'vizualizationApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
    endpoints: (builder) => ({
        getDataByYear: builder.query({
            query: (data) => createUrlParams(data),
        }),
        getDataByIndicator: builder.query({
            query: (data) => createDataByIndicatorUrlParams(data),
        }),
    }),
})

export const { useGetDataByYearQuery, useGetDataByIndicatorQuery } = vizualizationApi;