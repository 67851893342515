import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { prepareColumnsCountriesData } from "./columnsCountriesChart.utils";


export const useGetDataByYear = (indicatorId, selectedRegion) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => {
        if (selectedRegion) {
            const organizationId = selectedRegion.id;
            return `${basePath}/get_data_by_year.php?indicator=${indicatorId}&fullDataset=1&organization=${organizationId}`;
        }
        return `${basePath}/get_data_by_year.php?indicator=${indicatorId}&fullDataset=1`;
    }, [indicatorId, selectedRegion]);

    useEffect(() => {
        if (!indicatorId) {
            setData(null);
            return;
        };
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId, selectedRegion]);

    return { data, isLoading }
}

export const useGetOneIndicator = (indicatorId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_one_indicator.php?indicator=${indicatorId}`, [indicatorId]);

    useEffect(() => {
        if (!indicatorId) return;
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId]);

    return { data, isLoading }
}

export const useColumnsCountriesChart = (chartName, data, params, title) => {

    const [htmlDiagram, setHtmlDiagram] = useState('');

    const createChart = useCallback(() => {
        if (!data) return;
        const chartData = prepareColumnsCountriesData(data, params);

        let strArray = [
            `<script src="https://cdn.amcharts.com/lib/5/index.js"></script>\r\n`,
            `<script src="https://cdn.amcharts.com/lib/5/xy.js"></script>\r\n`,
            `<script src="//cdn.amcharts.com/lib/5/themes/Animated.js"></script>\r\n`,
            `<div id="${chartName}" style="width: ${params.width}; height: ${params.height}"></div>\r\n`,
            `<script>\r\n`
        ];

        strArray.push(`\tvar jsonChartData = '${JSON.stringify(chartData)}';\r\n`);
        strArray.push(`\tvar chartData = JSON.parse(jsonChartData);\r\n`);

        strArray.push(`\tvar root = am5.Root.new('${chartName}');\r\n`);
        strArray.push(`\troot.setThemes([
            am5themes_Animated.new(root)
        ]);\r\n`);

        strArray.push(`\tvar chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                ${params.zoomEnable ?
                `wheelX: "panX",
                wheelY: "zoomX",
                ` : ''}layout: root.verticalLayout
            })
        );\r\n`);

        if (params.showTitle) {
            strArray.push(`\tchart.topAxesContainer.children.push(am5.Label.new(root, {
                text: '${title}',
                fontSize: 20,
                fontWeight: "400",
                x: am5.p50,
                centerX: am5.p50
            }));\r\n`);
        }

        if (params.scrollbarEnable) {
            strArray.push(`\tchart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));\r\n`);
        }

        strArray.push(`\tlet cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));\r\n`);
        strArray.push(`\tcursor.lineY.set("visible", false);\r\n`);

        strArray.push(`\tlet yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );\r\n`);

        strArray.push(`\tvar xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {}),
                categoryField: "name"
            })
        );\r\n`);

        strArray.push(`\txAxis.data.setAll(chartData);\r\n`);

        strArray.push(`\tvar series1 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "name",
                minBulletDistance: 50,
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{categoryX}: {valueY} {unit}"
                })
            })
        );\r\n`);
        strArray.push(`\tseries1.data.setAll(chartData);\r\n`);

        strArray.push(`\tvar yRenderer = yAxis.get("renderer");\r\n`);
        strArray.push(`\tyRenderer.labels.template.setAll({
            fontSize: "0.7em",
            rotation: -60,
            centerY: am5.p50
        });\r\n`);

        strArray.push(`\tseries1.columns.template.setAll({ cornerRadiusTL: 0, cornerRadiusTR: 0 });\r\n`);
        strArray.push(`\tseries1.columns.template.set("interactive", true);\r\n`);

        if (params.showLegend) {
            strArray.push(`\tvar legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50)
            }));\r\n`);
            strArray.push(`\tlegend.data.setAll(chart.series.values);\r\n`);
        }

        strArray.push(`\tseries1.appear(500);\r\n`);

        strArray.push(`</script>`);
        setHtmlDiagram(strArray.join(''));
    }, [chartName, data, params, title]);

    return { htmlDiagram, createChart }
}