import { useEffect, useState, useCallback } from "react";

const defaultParams = {
    width: "100%",
    height: "600px",
    showLegend: true,
    sumValue: false,
    zoomEnable: true,
    scrollbarEnable: true,
    showTitle: true,
    unit: '',
};

export const useColumnsCountriesParams = (indicator) => {

    const [params, setParams] = useState(defaultParams);

    useEffect(() => {
        const isSumValue = indicator?.sumValue?.value;
        const unit = indicator?.unit ?? '';
        indicator && setParams((prev) => ({
            ...prev,
            sumValue: isSumValue,
            unit: unit,
        }))
    }, [indicator])

    const changeTextField = (name, event) => {
        const value = event.target.value;
        setParams((prev) => ({ ...prev, [name]: value }))
    }

    const changeNumberField = (name, event) => {
        const value = Number(event.target.value);
        // if (value <= maxItemsCount)
        setParams((prev) => ({ ...prev, [name]: value }))
    }

    const changeBoolField = (name) => {
        setParams((prev) => ({ ...prev, [name]: !prev[name] }))
    }

    const resetParams = useCallback(() => {
        setParams((prev) => ({ ...defaultParams, sumValue: prev.sumValue, unit: prev.unit }));
    }, [])

    const actions = {
        changeTextField,
        changeBoolField,
        changeNumberField,
        resetParams,
    }

    return { params, actions }
}