import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { vizualizationApi } from './api/vizualization.api'
import { dictionariesApi } from './api/dictionaries.api'
import { infoApi } from './api/info.api'
import { generalApi } from './api/general.api'
import { dataSlice } from './reducer/dataSlice'

export const store = configureStore({
    reducer: {
        // Add the generated reducer as a specific top-level slice
        [vizualizationApi.reducerPath]: vizualizationApi.reducer,
        [dictionariesApi.reducerPath]: dictionariesApi.reducer,
        [infoApi.reducerPath]: infoApi.reducer,
        [generalApi.reducerPath]: generalApi.reducer,
        [dataSlice.reducerPath]: dataSlice.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(vizualizationApi.middleware)
            .concat(dictionariesApi.middleware)
            .concat(infoApi.middleware)
            .concat(generalApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)