
export const prepareLinesData = (dataByYear, params) => {
    if (!dataByYear) return [];
    const { data, info } = dataByYear;
    const sumValue = params.sumValue;
    const unit = params.unit;

    let yearArray = [];
    for (let i = info?.yearFrom; i <= info?.yearTo; i++) {
        yearArray.push(i);
    }

    const resultData = yearArray.reduce((acc, currentYear) => {
        let count = 0;
        const summ = data?.reduce((acc, item) => {
            if (item.year === currentYear) {
                count++;
                return acc + item.value
            }
            return acc;
        }, 0);


        const value = sumValue ? summ : count !== 0 ? summ / count : summ;

        return [...acc, { name: `${currentYear}`, value: value, unit }]
    }, []);

    return resultData;
}
