import { Checkbox, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import { mainGridSx, paramsSx, paramsTextField, paramsTitleSx } from "./columnsCountriesChartParams.styles";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

export const ColumnsCountriesChartParams = ({ params, actions }) => {

    const { changeTextField, changeBoolField, changeNumberField } = actions;

    return (
        <Grid container sx={mainGridSx}>
            <Grid container sx={paramsSx}>
                <Grid container flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <Grid container sx={paramsTitleSx}>Настройки графика</Grid>
                    <Grid>
                        <Tooltip title='Сбросить'>
                            <IconButton onClick={actions.resetParams}>
                                <ClearRoundedIcon color="action" />
                            </IconButton>
                        </Tooltip >
                    </Grid>
                </Grid>
                <Grid container gap='10px' alignItems='baseline'>
                    <TextField sx={paramsTextField} label='Ширина' size="small" value={params.width} onChange={(e) => changeTextField('width', e)} />
                    <TextField sx={paramsTextField} label='Высота' size="small" value={params.height} onChange={(e) => changeTextField('height', e)} />
                </Grid>
                {/* <Grid container gap='10px' alignItems='baseline'>
                    <TextField type="number" sx={paramsTextField} label='Количество элементов' size="small" value={params.itemsCount} onChange={(e) => changeTextField('itemsCount', e)} />
                </Grid> */}
                <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.zoomEnable} onClick={() => changeBoolField('zoomEnable')} />
                    Включить масштабирование по оси X
                </Grid>
                <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.scrollbarEnable} onClick={() => changeBoolField('scrollbarEnable')} />
                    Отображать полосу прокрутки
                </Grid>
                <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.showLegend} onClick={() => changeBoolField('showLegend')} />
                    Отображать легенду
                </Grid>
                <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.showTitle} onClick={() => changeBoolField('showTitle')} />
                    Отображать заголовок
                </Grid>
                {/* <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.scrollbarEnable} onClick={() => changeBoolField('scrollbarEnable')} />
                    Отображать полосу прокрутки
                </Grid> */}
                {/* <Grid container gap='10px' alignItems='baseline'>
                        </Grid> */}
                {/* {!!yearRange && <Grid container width='300px' flexDirection='column' alignItems='flex-start' gap='5px' paddingLeft='10px'>
                    <Grid>Диапазон</Grid>
                    <Slider
                        // sx={{ width: '400px' }}
                        min={min}
                        max={max}
                        value={yearRange}
                        onChange={changeSlider}
                        marks={marks}
                        valueLabelDisplay="auto"
                    />
                </Grid>} */}
                {/* <Grid width={'300px'}><Slider min={1997} max={2024} value={[2014, 2020]} /></Grid> */}
            </Grid>
        </Grid>
    );
}

