import { Chip, Grid, IconButton, LinearProgress, Tooltip } from "@mui/material";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Header } from "../../components/header";
import { useNavigate, useParams } from "react-router-dom";
import { tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import { useColumnsCountriesChart, useGetDataByYear, useGetOneIndicator } from "./columnsCountriesChart.hooks";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { headerDescriptionTextSx, headerGridSx, headerTextSx } from "./columnsCountriesChart.styles";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { ChooseYear } from "../../components/chooseYear/chooseYear";
import { ChooseRegion } from "../../components/chooseRegion/chooseRegion";
import { VisualizationLinks } from "../../components/visualizationLinks/visualizationLinks";
import { nanoid } from "nanoid";
import { CopyHtmlGrid } from "../../components/copyHtmlGrid";
import { prepareColumnsCountriesData } from "./columnsCountriesChart.utils";
import { ColumnsCountriesChartParams, useColumnsCountriesParams } from "./columnsCountriesChartParams";
import { ChartTypeLinks } from "../../components/chartTypeLinks";
import { useGetOneIndicatorQuery } from "../../store/api/info.api";
import { useSelector } from "react-redux";
import { useGetDataByIndicatorQuery, useGetDataByYearQuery } from "../../store/api/vizualization.api";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

const vizualizationType = 'columncountries';
export const ColumnsCountriesChart = () => {
    const navigate = useNavigate();
    const chartName = useMemo(() => `statbase_${nanoid(10)}`, []);
    const { indicatorId } = useParams();
    const selectedRegion = useSelector((state) => state.dataSlice.region);
    const setSelectedRegion = () => { };
    // const [selectedRegion, setSelectedRegion] = useState(null);
    const { data: indicator } = useGetOneIndicatorQuery(indicatorId, { skip: !indicatorId });
    // const { data: indicator } = useGetOneIndicator(indicatorId);
    const { data: dataByYear, isFetching: isLoading } = useGetDataByIndicatorQuery({ indicatorId, selectedRegion, fullDataset: true });
    // const { data: dataByYear, isLoading } = useGetDataByYear(indicatorId, selectedRegion);
    const { params, actions } = useColumnsCountriesParams(indicator);

    const title = `${indicator?.name} | ${selectedRegion?.label ?? 'Весь мир'}`;
    const { htmlDiagram, createChart } = useColumnsCountriesChart(chartName, dataByYear, params, title);

    useEffect(() => {
        const accessCode = localStorage.getItem('accessCode');
        const activationTime = localStorage.getItem('activationTime');
        if (!accessCode || !activationTime) {
            navigate('/demo-access');
        }
    }, []);

    useLayoutEffect(() => {
        if (!dataByYear) return;
        const chartData = prepareColumnsCountriesData(dataByYear, params);

        am5.addLicense("AM5C404071029");
        let root = am5.Root.new(chartName);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                ...(params.zoomEnable ? {
                    wheelX: "panX",
                    wheelY: "zoomX",
                } : {}),
                pinchZoomX: true,
                layout: root.verticalLayout
            })
        );

        if (params.showTitle) {
            chart.topAxesContainer.children.push(am5.Label.new(root, {
                text: title,
                fontSize: 20,
                fontWeight: "400",
                x: am5.p50,
                centerX: am5.p50
            }));
        }

        if (params.scrollbarEnable) {
            chart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));
        }

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        // Create Y-axis
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        // Create X-Axis
        let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {}),
                categoryField: "name"
            })
        );
        xAxis.data.setAll(chartData);


        // Create series
        let series1 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                // name: series1Name,
                // name: 'series1Name',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "name",
                minBulletDistance: 50,
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    labelText: `{categoryX}: {valueY} {unit}`
                    // labelText: `{categoryX}: {valueY} ${getUnit(indicator, formula)}`
                })
            })
        );
        series1.data.setAll(chartData);

        // if (isColor(params.color)) {
        //     series1.set("fill", am5.color(params.color));
        //     series1.set("stroke", am5.color(params.color));
        // }

        var yRenderer = yAxis.get("renderer");
        yRenderer.labels.template.setAll({
            fontSize: "0.7em",
            rotation: -60,
            centerY: am5.p50
        });


        series1.columns.template.setAll({ cornerRadiusTL: 0, cornerRadiusTR: 0 });
        series1.columns.template.set("interactive", true);

        // if (isColor(params.hoverColor)) {
        //     series1.columns.template.states.create("hover", {
        //         fill: am5.color(params.hoverColor),
        //         stroke: am5.color(params.hoverColor)
        //     });
        // }

        if (params.showLegend) {
            var legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50)
            }));
            legend.data.setAll(chart.series.values);
        }

        series1.appear(500);

        return () => {
            root.dispose();
        };
    }, [chartName, dataByYear, params, title]);

    return (
        <>
            <Header />
            <Grid container sx={headerGridSx}>
                <ChartTypeLinks type={vizualizationType} />
                <ChooseYear indicator={indicator} type={vizualizationType} />
                {!indicator ?
                    <LinearProgress /> :
                    <>
                        <Grid container justifyContent='space-between' alignItems='center' mb='10px'>
                            <Grid container sx={headerTextSx}>
                                {`${indicator?.name} | ${selectedRegion?.label ?? 'Весь мир'}`}
                                {indicator?.description &&
                                    <NoMaxWidthTooltip
                                        title={
                                            <Grid sx={{ fontSize: '14px' }}>
                                                {indicator?.description}
                                            </Grid>
                                        }
                                    >
                                        <IconButton>
                                            <InfoRoundedIcon color="info" />
                                        </IconButton>
                                    </NoMaxWidthTooltip>
                                }
                            </Grid>
                            <Grid width='auto' container gap='5px' >{indicator?.sources?.map((item) => <Chip label={item?.name} />)}</Grid>
                        </Grid>
                        {indicator?.text && <Grid container sx={headerDescriptionTextSx}>
                            {indicator?.text}
                        </Grid>}
                    </>
                }
                <ColumnsCountriesChartParams params={params} actions={actions} />
                <ChooseRegion selectedRegion={selectedRegion} onChangeRegion={setSelectedRegion} type={vizualizationType} />
                <VisualizationLinks
                    showColumncountries={indicator?.isFullDataset}
                    showPiechart={indicator?.sumValue?.value}
                    type={vizualizationType}
                />
                {isLoading && <LinearProgress />}
                <Grid width={'100%'} container gap={'10px'}>
                    <div id={chartName} style={{ width: params.width, height: params.height }}></div>
                    <CopyHtmlGrid htmlDiagram={htmlDiagram} createChart={createChart} />
                </Grid>
            </Grid>
        </>
    );
}

