import { useCallback, useState } from "react";
import { prepareLinesData } from "./linesChart.utils";


export const useLineChart = (chartName, data, params, title) => {

    const [htmlDiagram, setHtmlDiagram] = useState('');

    const createChart = useCallback(() => {
        if (!data) return;
        const chartData = prepareLinesData(data, params);

        let strArray = [
            `<script src="https://cdn.amcharts.com/lib/5/index.js"></script>\r\n`,
            `<script src="https://cdn.amcharts.com/lib/5/xy.js"></script>\r\n`,
            `<script src="//cdn.amcharts.com/lib/5/themes/Animated.js"></script>\r\n`,
            `<div id="${chartName}" style="width: ${params.width}; height: ${params.height}"></div>\r\n`,
            `<script>\r\n`
        ];

        strArray.push(`\tvar jsonChartData = '${JSON.stringify(chartData)}';\r\n`);
        strArray.push(`\tvar chartData = JSON.parse(jsonChartData);\r\n`);

        strArray.push(`\tvar root = am5.Root.new('${chartName}');\r\n`);
        strArray.push(`\troot.setThemes([
            am5themes_Animated.new(root)
        ]);\r\n`);

        strArray.push(`\tvar chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                ${params.zoomEnable ?
                `wheelX: "panX",
                wheelY: "zoomX",
                ` : ''}layout: root.verticalLayout
            })
        );\r\n`);

        if (params.showTitle) {
            strArray.push(`\tchart.topAxesContainer.children.push(am5.Label.new(root, {
                text: '${title}',
                fontSize: 20,
                fontWeight: "400",
                x: am5.p50,
                centerX: am5.p50
            }));\r\n`);
        }

        if (params.scrollbarEnable) {
            strArray.push(`\tchart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));\r\n`);
        }

        strArray.push(`\tlet cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));\r\n`);
        strArray.push(`\tcursor.lineY.set("visible", false);\r\n`);

        strArray.push(`\tlet yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );\r\n`);

        strArray.push(`\tvar xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {}),
                categoryField: "name"
            })
        );\r\n`);

        strArray.push(`\txAxis.data.setAll(chartData);\r\n`);

        strArray.push(`\tvar series1 = chart.series.push(
            am5xy.LineSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "name",
                minBulletDistance: 50,
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{categoryX}: {valueY} {unit}"
                })
            })
        );\r\n`);
        strArray.push(`\tseries1.data.setAll(chartData);\r\n`);

        strArray.push(`\tseries1.bullets.push(function () {
            var bulletCircle = am5.Circle.new(root, {
                radius: 5,
                fill: series1.get("fill")
            });
            return am5.Bullet.new(root, {
                sprite: bulletCircle
            })
        });\r\n`);

        // strArray.push(`\tvar yRenderer = yAxis.get("renderer");\r\n`);
        // strArray.push(`\tyRenderer.labels.template.setAll({
        //     fontSize: "0.7em",
        //     rotation: -60,
        //     centerY: am5.p50
        // });\r\n`);

        // strArray.push(`\tseries1.columns.template.setAll({ cornerRadiusTL: 0, cornerRadiusTR: 0 });\r\n`);
        // strArray.push(`\tseries1.columns.template.set("interactive", true);\r\n`);

        if (params.showLegend) {
            strArray.push(`\tvar legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50)
            }));\r\n`);
            strArray.push(`\tlegend.data.setAll(chart.series.values);\r\n`);
        }

        strArray.push(`\tseries1.appear(500);\r\n`);

        strArray.push(`</script>`);
        setHtmlDiagram(strArray.join(''));
    }, [chartName, data, params, title]);

    return { htmlDiagram, createChart }
}